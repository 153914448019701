import { Link } from 'react-router-dom'
import { Card } from '@jsluna/card'
import { GridItem, GridWrapper } from '@jsluna/grid'
import { ArrowRight } from '@jsluna/icons'
import checkAlarmType from '../../../../utils/checkAlarmType'
import { setCardColor } from '../../../../utils/cardColor'
import Tag from '../../Tag'
import AlarmHistoryLog from '../../Components/AlarmHistoryLog'
import Title from '../../Components/Title'
import Description from '../../Components/Description'
import NextAction from '../../Components/NextAction'
import { IAlarms } from '../../../../types'

interface SiteAlarmCard {
  alarm: IAlarms["results"][0] | undefined | any
}

const SiteAlarmCard = ({ alarm }: SiteAlarmCard) => {
  const alarmDetails = checkAlarmType(alarm)

  let card : {
    cardColor: string,
    tagColor: string,
  } | null = null
  if (alarm.status !== 'Closed') {
    card = setCardColor(alarm)
  }

  return (
    <GridItem key={alarm.id} size="10/10">
      <Link to={`/fixturelist/${alarm.assetNumber}`} className="a-no-link-style">
        <Card
          id={`r-${alarm.alarmName.toLowerCase().replace(/ /g, '-')}-card`}
          className={`r-site-alarm-card ${
            alarm.status !== 'Closed' ? card && card.cardColor : 'r-assetCard-noTag'
          }`}
        >
          <GridWrapper verticalAlign="middle">
            <GridItem size="9/10">
              <Title text={alarmDetails && alarmDetails.title} />
              <Description text={alarmDetails && alarmDetails.description} />
              <Tag
                className=" ln-u-soft-bottoms"
                alarm={alarm}
                isAlarmsPage
                tagColor={card && card.tagColor}
              />
              <AlarmHistoryLog alarm={alarm} />
              <NextAction alarm={alarm} tagColor={card && card.cardColor} />
            </GridItem>
            <GridItem size="1/10">
              <ArrowRight size="large" />
            </GridItem>
          </GridWrapper>
        </Card>
      </Link>
    </GridItem>
  )
}

export default SiteAlarmCard
