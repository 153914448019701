import { useEffect } from 'react'
import { Container, GridWrapper } from '@jsluna/react'
import { Card } from '@jsluna/card'
import { ProgressSpinner } from '@jsluna/progress'
import { Display3 } from '@jsluna/typography'
import AlarmCard from '../Cards/AlarmCard'
import ErrorMessage from '../ErrorMessage'
import {
  WaterLeakAlarmName,
  LeakAlarmName,
  SiteCommunicationAlarmName,
  PackAlarmName,
} from '../../utils/alarmConstants'
import SiteCommunicationAlarmCard from '../Cards/AlarmCard/SiteCommunicationAlarmCard'
import SiteAlarmCard from '../Cards/AlarmCard/SiteAlarmCard'
import useAlarms from '../../hooks/useAlarms'
import { useCurrentStore } from '../../hooks'

interface StoreAlarmsParams {
  filters: any,
}

const StoreAlarms = ({filters}: StoreAlarmsParams) => {
  const storeCode = useCurrentStore().storeCode
  const [alarmsResource, alarmsLoader] = useAlarms()
  let alarmsData = null

  useEffect(() => {
    alarmsLoader.current(storeCode, filters, 0, "site")
  }, [alarmsLoader, filters])

  if(alarmsResource.hasLoaded && !alarmsResource.hasError){
    alarmsData = alarmsResource?.data?.results
  }

  const pageTitle = () => {
    return (
      <Container className="r-pageName ln-u-padding-ends*2" size="xs">
        <Display3 id="r-page-title" className="r-page-title">
            Store Alarms
        </Display3>
      </Container>
    )
  }

  if(!alarmsResource.hasLoaded && !alarmsResource.hasError) return (
    <Container className="r-alarmsList" size="xs">
      {pageTitle()}
      <Card id="r-loadingElement" className="ln-u-text-align-center">
          <ProgressSpinner size="large" />
      </Card>
    </Container>
  )


  if(alarmsResource.hasError && filters?.length > 0) return (
    <>
      {pageTitle()}
      <Container className="r-alarmsList" size="xs">
        <ErrorMessage errorMessage="There's no alarms which match your filter." />
      </Container>
    </>
  )

  if(alarmsResource.hasError && 
    (!alarmsData || alarmsData?.length <= 0) &&
    filters?.length === 0) return (
      <>
        {pageTitle()}
        <Container className="r-alarmsList" size="xs">
          <ErrorMessage errorMessage="No Current Store Alarms" />
        </Container>
      </>
    )
    

  if (alarmsData)
  return (
    <>
      {pageTitle()}
      <Container className="r-alarmsList" size="xs">
        <GridWrapper
          matrix
          gutterSize="sm"
          className="ln-o-grid--gutter-zero@max-mobile"
        >
          {alarmsData.map((storeAlarm:any, index) => {
              if (storeAlarm.alarmName === SiteCommunicationAlarmName)
                return (<SiteCommunicationAlarmCard key={index}
                    alarm={storeAlarm}
                    siteAlarmHistory={storeAlarm.eventsHistoryResponse}
                />)
              if (
                storeAlarm.alarmName === WaterLeakAlarmName ||
                storeAlarm.alarmName === LeakAlarmName ||
                storeAlarm.alarmName === PackAlarmName
              ) return <SiteAlarmCard key={storeAlarm.assetNumber} alarm={storeAlarm} />
              return (<AlarmCard key={storeAlarm.assetNumber}
                alarm={storeAlarm}
                modelType="alarmDetails"
              />)
            })}
        </GridWrapper>
      </Container>
    </>
  )
}

export default StoreAlarms;
