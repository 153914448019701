import { useContext, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Card } from '@jsluna/card'
import { GridItem, GridWrapper } from '@jsluna/grid'
import { ArrowRight } from '@jsluna/icons'
import { setCardColor } from '../../../../utils/cardColor'
import Tag from '../../Tag'
import AlarmHistoryLog from '../../Components/AlarmHistoryLog'
import Title from '../../Components/Title'
import Description from '../../Components/Description'
import NextAction from '../../Components/NextAction'
import { AppContext } from '../../../../context/AppContext'
import { IAlarms } from '../../../../types'

interface SiteCommunicationAlarmCardParams {
  alarm: IAlarms["results"][0] | undefined | any
  siteAlarmHistory: any,
  design?: string,
}

const SiteCommunicationAlarmCard = ({alarm, siteAlarmHistory, design}: SiteCommunicationAlarmCardParams) => {
  const context = useContext(AppContext)
  
  useEffect(() => {
    if (context && (context.appContextData?.siteCommunicationAlarm && !(Object.prototype.hasOwnProperty.call(context.appContextData.siteCommunicationAlarm, `siteCommunicationAlarm${alarm.externalId}`)))){
        context.setSpecificData('siteCommunicationAlarm', `siteCommunicationAlarm${alarm.externalId}`, alarm)
        const storedState = sessionStorage.getItem('AppContextData');
          if (storedState && context) {
            context.appContextData = JSON.parse(storedState);
        }
    }

    if (context && (context.appContextData?.siteAlarmHistory && !(Object.prototype.hasOwnProperty.call(context.appContextData?.siteAlarmHistory, `siteAlarmHistory${alarm.externalId}`)))){
      context.setSpecificData('siteAlarmHistory', `siteAlarmHistory${alarm.externalId}`, alarm)
      const storedState = sessionStorage.getItem('AppContextData');
        if (storedState && context) {
          context.appContextData = JSON.parse(storedState);
      }
    }
  }, [alarm, siteAlarmHistory])

  let card : {
    cardColor: string,
    tagColor: string,
  } | null = null
  if (alarm.status !== 'Closed') {
    card = setCardColor(alarm)
  }

  return (
    <GridItem size="10/10"
      className={design === 'detailsPage' ? 'ln-u-hard' : ''}
      key={alarm.id}
    >
      <Link
        to={`/siteCommunicationAlarm/${alarm?.externalId}`}
        className="a-no-link-style"
      >
        <Card
          id="r-siteCommunicationAlarm"
          className={`r-alarm-card ${
            alarm.status !== 'Closed' ? card && card.cardColor : 'r-assetCard-noTag'
          }`}
        >
          <GridWrapper verticalAlign="middle">
            <GridItem size="9/10">
              <Title
                text={
                  alarm.siteName[0] === 'P'
                    ? 'Petrol station communication alarm'
                    : 'Site communication alarm'
                }
              />
              <Description text="All fixtures are affected" />
              <Tag
                className=" ln-u-soft-bottoms"
                alarm={alarm}
                isAlarmsPage={design !== 'detailsPage'}
                tagColor={card && card.tagColor}
              />

              {design === 'detailsPage' ? null : (
                <AlarmHistoryLog alarm={alarm} />
              )}
              <NextAction alarm={alarm} tagColor={card && card.cardColor} />
            </GridItem>

            {design === 'detailsPage' ? null : (
              <GridItem size="1/10">
                <ArrowRight size="large" />
              </GridItem>
            )}
          </GridWrapper>
        </Card>
      </Link>
    </GridItem>
  )
}

export default SiteCommunicationAlarmCard
