import { useState } from 'react'
import { Card } from '@jsluna/card'
import { Container } from '@jsluna/react'
import { Body1, Display1, Caption } from '@jsluna/typography'
import { FormGroup, Label } from '@jsluna/form'
import { FilledButton } from '@jsluna/button'
import { foodSafetyGuide } from '../../../../utils/linkConstants'
import alarmsApi from '../../../../api/alarmsApi'
import getRelativeDate from '../../../../utils/relativeDate'
import { ProgressSpinner } from '@jsluna/progress'
import WorkOrderLink from '../../WorkOrderLink/WorkOrderLink'
import { useApiClient } from '../../../../context/AppContext'

interface PackAlarmParams {
  alarm: {
    assetNumber: string,
    id: string,
    lastResponse: string,
    responseTime: string,
    user: string,
    woNumber: string,
    alarmImages: {
      length: number,
    }
  },
  historyEvents: Array<any> | null,
  storeCode: string,
  userName: string,
}

const PackAlarm = ({ alarm, historyEvents, storeCode, userName }: PackAlarmParams) => {
  const [errorMessage, setErrorMessage] = useState('')
  const [responseSent, setResponseSent] = useState(false)
  let alarmIsAnswered = false
  let buttonIsDisabled = false
  let user = userName
  let date = new Date()
  const [loading, setLoading] = useState(false)
  const apiClient = useApiClient()
  const sendResponse = async () => {
    buttonIsDisabled = true
    setLoading(true)
    const postAlarmResponse = await alarmsApi.PostAlarmResponse(
      apiClient,
      storeCode,
      userName,
      alarm,
      'confirm_action',
      'pack alarm acknowledged',
    )
    if (postAlarmResponse?.error) {
      setErrorMessage(postAlarmResponse.data ?? 'Unable to submit the response')
      buttonIsDisabled = false
    } else {
      setResponseSent(true)
    }
    setLoading(false)
  }

  if (loading) {
    return (
      <Container
        className="ln-u-text-align-center ln-u-padding-top*4"
        size="xs"
      >
        <ProgressSpinner />
      </Container>
    )
  }

  const checkAlarmEvents = () => {
    historyEvents && historyEvents.forEach((event: any) => {
      if (event?.alarmId === alarm?.id) {
        if (event?.answerType === 'confirm_action') {
          alarmIsAnswered = true
          user = event.user
          date = event.timestamp
          buttonIsDisabled = true
          return true
        }
      }
      return false
    })
  }
  checkAlarmEvents()

  return (
    <Container id="r-PackAlarmForm" className="ln-u-padding-ends*3" size="xs">
      <div className="ln-u-padding*2">
        <Display1>
          Take manual temperature checks while we fix the problem
        </Display1>
      </div>
      <Card>
        <div className="ln-u-padding-bottom*3">
          This pack has a critical issue, affecting multiple fixtures, so stock
          could be at risk.
        </div>
        <div>
          A work order&apos;s been created, so an engineer can investigate
          (remotely or in store). If the engineer needs a fixture demerchandise
          they&apos;ll be in touch.
        </div>
        <Label className="ln-u-soft-top ln-u-flush-bottom">You need to:</Label>
        <ul className="ln-u-flush-bottom">
          <li>
            Go to 2 fixtures per cabinet run, inc. counters.{' '}
            <b>Take manual temperature checks within 30 mins.</b> Check top,
            middle and bottom of the fixtures, using an air probe. Record these
            temperatures on your Manual Temperature Check sheet.
          </li>
          <li>
            <b>Schedule manual temperatures checks every 30 minutes</b> for 2
            fixtures per cabinet run, inc. counters. Check top, middle and
            bottom of the fixtures, using an air probe, until you receive an
            email saying alerts have been restored. Record these temperatures on
            your Manual Temperature Check sheet.
          </li>
          <li className="ln-u-font-weight-bold">
            If the pack has not been restored by 8am tomorrow. AM and PM manual
            temperature checks need to be taken for every fixture until
            communications been restored.
          </li>
        </ul>
        <Label className="ln-u-soft-top ln-u-flush-bottom">
          More information
        </Label>
        <div className="ln-u-soft-bottom">
          For more guidance and manual temperature sheets, please refer to:{' '}
          <Body1 className="ln-u-text-decoration-underline ln-u-font-weight-bold">
            <a href={foodSafetyGuide}>
              Food Safety: Guide for dealing with RTMS alarms and temperature
              checks
            </a>
          </Body1>
        </div>
        <FormGroup
          name="r-submit-button"
          error={errorMessage}
          className="ln-u-flush-bottom"
        >
          <FilledButton
            id="r-submitButton"
            fullWidth
            disabled={responseSent ? true : buttonIsDisabled}
            onClick={() => sendResponse()}
          >
            Start manual temperature checks
          </FilledButton>

          {alarmIsAnswered || responseSent ? (
            <div className="ln-u-margin-top">
              <Caption id="r-tag-reportingDate" className="ln-u-color-grey">
                Acknowledged by {user} on {getRelativeDate(date)}
              </Caption>
            </div>
          ) : null}
        </FormGroup>
        {alarm.woNumber === null || alarm.woNumber === '' ? null : (
          <WorkOrderLink assetNumber={alarm.assetNumber} />
        )}
      </Card>
    </Container>
  )
}

export default PackAlarm
