import { useContext, useEffect, useState } from 'react'
import assetsApi from '../../../../api/assetsApi'
import alarmsApi from '../../../../api/alarmsApi'
import { Card } from '@jsluna/card'
import { Container } from '@jsluna/react'
import { Display1, Caption } from '@jsluna/typography'
import { FormGroup, RadioButtonField, Label } from '@jsluna/form'
import { FilledButton, TextButton } from '@jsluna/button'
import {
  ProgressBar,
  ProgressIndicator,
  ProgressSpinner
} from '@jsluna/progress'
import ImageUpload from '../../../ImageUpload'
import getRelativeDate from '../../../../utils/relativeDate'
import IndexForm from '../IndexForm'
import ImageGuidance from '../ImageGuidance'
import { AppContext, useApiClient } from '../../../../context/AppContext'
import { IImages } from '../../../../types'

interface OverTemperatureAlarmPararms {
  alarm: {
    assetNumber: string
    assetDescription: string
    productType: string
    reason: string
    id: string
    lastResponse: string
    responseTime: string
    user: string
    woNumber: string
    status: string
    alarmName: string
    assetDepartment: string
    alarmResponseImages: {
      length: number
    }
    alarmImages: {
      length: number
    }
    isParentAlarmed: boolean
  }
  storeCode: string
  userName: string
  showDeMerchandise: boolean
  setShowDeMerchandise: React.Dispatch<React.SetStateAction<boolean>>
}

const OverTemperatureAlarm = ({
  alarm,
  storeCode,
  userName,
  showDeMerchandise,
  setShowDeMerchandise
}: OverTemperatureAlarmPararms) => {
  const [returnToIndexForm, setReturnToIndexForm] = useState(false)
  const [answer, setAnswer] = useState(alarm.lastResponse ?? '')
  const [uploadedImages, setUploadedImages] = useState<IImages[]>()
  const [answerErrorMessage, setAnswerErrorMessage] = useState('')
  const [errorMessage, setErrorMessage] = useState()
  const [imageErrorMessage, setImageErrorMessage] = useState<string>()
  const [loading, setLoading] = useState(false)
  const [imageLoading, setImageLoading] = useState(false)
  const [buttonIsDisabled, setButtonIsDisabled] = useState<boolean>(
    alarm.lastResponse && answer === 'dont_create_wo' ? true : false
  )
  const [uploadedImagesNumber, setUploadedImagesNumber] = useState(
    alarm.alarmResponseImages ? alarm.alarmResponseImages.length : 0
  )
  const [showCheckCompletedMessage, setShowCheckCompletedMessage] = useState(
    alarm.lastResponse === 'create_wo' ||
    alarm.lastResponse === 'dont_create_wo'
  )
  const [userResponded, setUserResponded] = useState(alarm.user)
  const [timeResponded, setTimeResponded] = useState(alarm.responseTime)
  const appcontext = useContext(AppContext)
  const apiClient = useApiClient()
  const getAlarmImagesURI = async () => {
    const response = await assetsApi.GetImages(
      apiClient,
      alarm.assetNumber,
      alarm.id,
      'RESPONSE'
    )

    if (response != null && response.length > 0) {
      setUploadedImages(response)
    } else setUploadedImages([])
  }

  useEffect(() => {
    setLoading(true)
    getAlarmImagesURI()
    setLoading(false)
  }, [])

  const updateAnswer = (value: any) => {
    setAnswer(value)
    setAnswerErrorMessage('')
    if (alarm.lastResponse === 'dont_create_wo' && value === 'dont_create_wo') {
      setButtonIsDisabled(true)
    }
    if (alarm.lastResponse === 'dont_create_wo' && value !== 'dont_create_wo') {
      setButtonIsDisabled(false)
    }
  }

  const sendAlarmResponse = async (response: any) => {
    setLoading(true)
    const postAlarmResponse = await alarmsApi.PostAlarmResponse(
      apiClient,
      storeCode,
      userName,
      alarm,
      response
    )
    if (postAlarmResponse?.error) {
      setErrorMessage(postAlarmResponse.data ?? 'Unable to submit the response')
      setButtonIsDisabled(false)
    } else {
      setButtonIsDisabled(true)
      setUserResponded(userName)
      setTimeResponded(new Date().toString())
      setShowCheckCompletedMessage(true)
      if (response === 'create_wo') {
        setShowDeMerchandise(true)
      }
    }
    if (appcontext && appcontext.appContextData.assets[alarm.assetNumber]) {
      appcontext.deleteSpecificData("assets", alarm.assetNumber)
      delete appcontext.appContextData.assets[alarm.assetNumber]
    }
    setLoading(false)
  }

  const sendImages = async (images: any) => {
    setImageLoading(true)
    const imageResponse = await assetsApi.UploadFiles(
      apiClient,
      alarm.assetNumber,
      alarm.id,
      'RESPONSE',
      images
    )
    if (imageResponse?.uploadedImagesCount > 0) {
      setUploadedImagesNumber(
        uploadedImagesNumber + imageResponse.uploadedImagesCount
      )
      setImageErrorMessage('')
      await getAlarmImagesURI()
    }

    if (imageResponse.error) {
      setImageErrorMessage(
        imageResponse.errorMessages ?? 'Unable to upload one or more image(s)'
      )
    }
    setImageLoading(false)
  }

  const sendResponse = async () => {
    switch (true) {
      case answer === '' &&
        (alarm.lastResponse === '' || alarm.lastResponse === null): {
          setAnswerErrorMessage('Please select one of the options')
          break
        }
      case answer !== '' && alarm.isParentAlarmed: {
        await sendAlarmResponse(answer)
        break
      }
      case answer !== '' && uploadedImages && uploadedImages.length > 0: {
        await sendAlarmResponse(answer)
        break
      }
      case uploadedImages && uploadedImages.length === 0: {
        setImageErrorMessage('Please add at least 1 image')
        setButtonIsDisabled(false)
        break
      }
      default:
    }
  }

  const deleteImage = async (imageName: any) => {
    setImageLoading(true)
    const response = await assetsApi.DeleteImage(
      apiClient,
      alarm.assetNumber,
      alarm.id,
      imageName
    )
    if (response.error) setImageErrorMessage(response.errorMessage)
    await getAlarmImagesURI()
    setImageLoading(false)
  }

  const checkCompletedMessage = () => {
    return (
      <div className="ln-c-form-group">
        <Caption id="r-caption-responseCheck" className="ln-u-color-grey">
          Check completed by&nbsp;{userResponded} on&nbsp;
          {getRelativeDate(timeResponded)}
        </Caption>
      </div>
    )
  }

  const showImageUpload = () => {
    return (
      <div id="r-imageUpload">
        <Label htmlFor="Upload image">Upload image</Label>
        <div className="ln-u-padding-bottom*2">
          Take photo after resolving identifiable issues.{' '}
          <ImageGuidance alarm={alarm} showDeMerchandise={showDeMerchandise} />
        </div>
        <ImageUpload
          uploadedImages={uploadedImages}
          buttonDisabled={
            uploadedImages && uploadedImages.length >= 3 ? true : false
          }
          errorMessage={imageErrorMessage}
          sendImages={sendImages}
          removeImage={deleteImage}
          lastResponseTime={timeResponded}
        />
        {imageLoading ? (
          <ProgressIndicator loading preventFocus>
            <ProgressBar
              color="light"
              size="small"
              className="ln-u-margin-right"
            />
          </ProgressIndicator>
        ) : null}
      </div>
    )
  }

  if (loading) {
    return (
      <Container
        className="ln-u-text-align-center ln-u-padding-top*4"
        size="xs"
      >
        <ProgressIndicator
          className="ln-u-justify-content-center"
          loading
          preventFocus
          style={{ position: 'sticky', zIndex: 2 }}
        >
          <ProgressSpinner size="small" />
        </ProgressIndicator>
      </Container>
    )
  }

  if (returnToIndexForm) {
    return <IndexForm alarm={alarm} />
  }

  return (
    <Container
      id="r-overTemperatureAlarmForm"
      className="ln-u-padding-ends*3"
      size="xs"
    >
      <div className="ln-u-padding*2">
        <Display1>Visually check fixture</Display1>
      </div>
      <Card>
        The listed fixture(s) is <b>over temperature and needs checking. </b>{' '}
        The fixture is warmer than the range set for products in it, so stock is
        at risk.
        <Label htmlFor="alarmData" className="ln-u-soft-top">
          You need to:
        </Label>
        <ul className="ln-u-flush-bottom">
          <li>Check the fixture to rectify obvious problems:</li>
          <ul className="ln-u-flush-bottom">
            <li>Doors are closed</li>
            <li>Air vents aren’t blocked</li>
            <li>Fixture isn’t over stocked (reduce if needed)</li>
          </ul>
          <li>Record that you’ve checked the fixture below</li>
          <li>
            If everything seems normal and nothing can be resolved in store,
            create a work order for an engineer to investigate and resolve.
          </li>
        </ul>
        <Label htmlFor="alarmData" className="ln-u-soft-top">
          Things you don’t need to do:
        </Label>
        <ul className="ln-u-flush-bottom">
          <li>Don’t complete manual temperature checks</li>
          <li>
            If you’re tried to resolve in store, don’t create a work order.
            We’ll continue to monitor and a work order will be{' '}
            <b>created automatically</b>, if there’s still a problem, so an
            engineer can take corrective action.
          </li>
        </ul>
        <RadioButtonField
          id="r-answersToAlarm"
          label="Record your observation"
          name="radio-button-field"
          options={[
            {
              value: 'create_wo',
              label:
                'No visible issues, an engineer needs to investigate further',
              defaultChecked: answer === 'create_wo'
            },
            {
              value: 'dont_create_wo',
              label:
                'I found and corrected something that might have caused the alarm',
              defaultChecked: answer === 'dont_create_wo'
            }
          ]}
          fullWidth
          outlined
          onChange={(e: any) => {
            updateAnswer(e.target.value)
          }}
          error={answerErrorMessage}
        />
        {showCheckCompletedMessage ? checkCompletedMessage() : null}
        {!alarm.isParentAlarmed && !buttonIsDisabled ? showImageUpload() : null}
        <FormGroup
          name="r-submit-button"
          error={errorMessage}
          className="ln-u-flush-bottom"
        >
          <FilledButton
            id="r-submitButton"
            fullWidth
            disabled={
              (uploadedImages && uploadedImages.length > 3) ||
                imageLoading ||
                loading ||
                (!alarm.isParentAlarmed &&
                  uploadedImages &&
                  uploadedImages.length === 0) ||
                answer === ''
                ? true
                : buttonIsDisabled
            }
            onClick={() => sendResponse()}
          >
            Visual check complete
          </FilledButton>
        </FormGroup>
        <TextButton
          id="r-cancelButton"
          fullWidth
          color="dark"
          className="ln-u-margin-ends*2"
          disabled={buttonIsDisabled}
          onClick={() => setReturnToIndexForm(true)}
        >
          Cancel
        </TextButton>
      </Card>
    </Container>
  )
}

export default OverTemperatureAlarm
