import { useState } from 'react'
import { Card } from '@jsluna/card'
import { Container } from '@jsluna/react'
import { Display1, Caption } from '@jsluna/typography'
import { FormGroup, Label } from '@jsluna/form'
import { FilledButton } from '@jsluna/button'
import alarmsApi from '../../../../api/alarmsApi'
import getRelativeDate from '../../../../utils/relativeDate'
import { ProgressSpinner } from '@jsluna/progress'
import WorkOrderLink from '../../WorkOrderLink/WorkOrderLink'
import { useApiClient } from '../../../../context/AppContext'

interface LeakAlarmParams {
  alarm: {
    assetNumber: string,
    id: string,
    lastResponse: string,
    user: string,
    woNumber: string,
  },
  historyEvents: Array<any> | null,
  storeCode: string,
  userName: string
}

const LeakAlarm = ({ alarm, historyEvents, storeCode, userName }: LeakAlarmParams) => {
  const [errorMessage, setErrorMessage] = useState('')
  const [responseSent, setResponseSent] = useState(false)
  let alarmIsAnswered = false
  let buttonIsDisabled = false
  let user = userName
  let date = new Date()
  const [loading, setLoading] = useState(false)
  const apiClient = useApiClient()
  const sendResponse = async () => {
    buttonIsDisabled = true
    setLoading(true)
    const postAlarmResponse = await alarmsApi.PostAlarmResponse(
      apiClient,
      storeCode,
      userName,
      alarm,
      'confirm_action',
      'leak alarm acknowledged'
    )
    if (postAlarmResponse?.error) {
      setErrorMessage(postAlarmResponse.data ?? 'Unable to submit the response')
      buttonIsDisabled = false
    } else {
      setResponseSent(true)
    }
    setLoading(false)
  }

  if (loading) {
    return (
      <Container
        className="ln-u-text-align-center ln-u-padding-top*4"
        size="xs"
      >
        <ProgressSpinner />
      </Container>
    )
  }

  const checkAlarmEvents = () => {
    historyEvents && historyEvents.forEach((event: any) => {
      if (event?.alarmId === alarm?.id) {
        if (event?.answerType === 'confirm_action') {
          alarmIsAnswered = true
          user = event.user
          date = event.timestamp
          buttonIsDisabled = true
          return true
        }
      }
      return false
    })
  }
  checkAlarmEvents()

  return (
    <Container id="r-leakAlarmForm" className="ln-u-padding-ends*3" size="xs">
      <div className="ln-u-padding*2">
        <Display1>Refrigerant gas leaking</Display1>
      </div>
      <Card>
        <div>
          A work order has been created automatically so an engineer can
          investigate and resolve the problem either remotely or in store.
        </div>
        <Label className="ln-u-soft-top ln-u-flush-bottom">You need to:</Label>
        <ul className="ln-u-flush-bottom">
          <li>
            Inform all staff and evacuate the affected area immediately and make
            sure no one enters the area
          </li>
          <li>
            Leave the refrigerator switched on, as the fans will help disperse
            the gas
          </li>
          <li>Make sure the area is well ventilated</li>
          <li>Follow the emergency procedure when necessary</li>
        </ul>
        <Label className="ln-u-soft-top ln-u-flush-bottom">
          More information
        </Label>
        <div className="ln-u-soft-bottom">
          For more guidance, clarification or reference, please refer to
          emergency procedures
        </div>
        <FormGroup
          name="r-submit-button"
          error={errorMessage}
          className="ln-u-flush-bottom"
        >
          <FilledButton
            id="r-submitButton"
            fullWidth
            disabled={responseSent ? true : buttonIsDisabled}
            onClick={() => sendResponse()}
          >
            Mark as done
          </FilledButton>
          {alarmIsAnswered || responseSent ? (
            <div className="ln-u-margin-top">
              <Caption id="r-tag-reportingDate" className="ln-u-color-grey">
                Acknowledged by {user} on {getRelativeDate(date)}
              </Caption>
            </div>
          ) : null}
        </FormGroup>
        {alarm.woNumber === null || alarm.woNumber === '' ? null : (
          <WorkOrderLink assetNumber={alarm.assetNumber} />
        )}
      </Card>
    </Container>
  )
}

export default LeakAlarm
