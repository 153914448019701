import { ApiClient } from './ApiClient'
import webApi from './webApi'
import { IAlarms, IAlarmGuidance } from '../types'

const PostAlarmResponse = async (
  apiClient: ApiClient,
  storeCode: string,
  username: string,
  alarm: { assetNumber: any; id: any },
  responseType: any,
  responseComment: string | null = null,
  isDemerchandiseRequest = false
) => {
  const data = {
    siteName: storeCode,
    user: username,
    assetNumber: alarm.assetNumber,
    alarmId: alarm.id,
    responseType,
    comment: responseComment,
    isDemerchandiseRequest
  }

  const response = await webApi.post(apiClient, {
    url: `/api/alarms/response`,
    params: [],
    body: JSON.stringify(data)
  })

  const responseData = await response.json()
  if (response.status === 200) {
    return {
      error: false,
      data: responseData
    }
  } else {
    return {
      error: true,
      data: responseData
    }
  }
}

const GetAlarmsImageGuidance = async (
  apiClient: ApiClient,
  isDemerchandise: boolean,
  productType: string,
  assetDescription: string,
  assetDepartment: string
): Promise<IAlarmGuidance[]> => {
  const response = await webApi.get<IAlarmGuidance[]>(apiClient, {
    url: `/api/alarms/guidance`,
    params: {
      isDemerchandise: isDemerchandise,
      productType: productType,
      assetDescription: assetDescription,
      assetDepartment: assetDepartment
    }
  })
  return response
}

const GetAlarmsHistory = async (
  apiClient: ApiClient,
  storeCode: string,
  filters: any,
  pageNumber: number
): Promise<IAlarms> => {
  const filtersList: any[] = []

  if (filters) {
    filters.forEach((filterDetails: any) =>
      filtersList.push(filterDetails.filterValue.toUpperCase())
    )
  }

  const response = await webApi.get<IAlarms>(apiClient, {
    url: 'api/alarms/history',
    params: {
      siteName: storeCode,
      filters: filtersList.join(',').toString(),
      pageNumber: pageNumber
    }
  })
  return response
}

const GetAlarms = async (
  apiClient: ApiClient,
  storeCode: string,
  filters: any,
  pageNumber: number,
  alarmType: string
): Promise<IAlarms> => {
  const filtersList: any[] = []

  if (filters) {
    filters.forEach((filterDetails: any) =>
      filtersList.push(filterDetails.filterValue)
    )
  }

  const response = await webApi.get<IAlarms>(apiClient, {
    url: `api/alarms`,
    params: {
      siteName: storeCode,
      filters: filtersList.join(',').toString(),
      alarmType: alarmType,
      pageNumber: pageNumber
    }
  })
  return response
}

const alarmsApi = {
  GetAlarmsHistory,
  GetAlarmsImageGuidance,
  PostAlarmResponse,
  GetAlarms
}

export default alarmsApi
