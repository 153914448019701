import { PropTypes } from 'prop-types'
import { GridItem } from '@jsluna/grid'
import FmAssistAssetUrl from '../../../../../utils/fmAssistLink'
import WoMessageCard from './WoMessageCard'

function WorkOrderCreatedMessage({
  event,
  assetNumber,
  link = 'viewWorkOrder',
}) {
  const showFmAssistLink = true
  return (
    <GridItem className="ln-u-hard" size="10/10">
      <div className="r-event-workOrder-created ln-u-padding-bottom">
        {assetNumber === null ? (
          <WoMessageCard event={event} showFmAssistLink={!showFmAssistLink} />
        ) : (
          <a
            id="r-woLink"
            href={FmAssistAssetUrl(assetNumber, link)}
            target="_blank"
            rel="noreferrer"
            className="a-no-link-style"
          >
            <WoMessageCard event={event} showFmAssistLink={showFmAssistLink} />
          </a>
        )}
      </div>
    </GridItem>
  )
}

WorkOrderCreatedMessage.propTypes = {
  event: PropTypes.shape({
    alarmId: PropTypes.string,
    eventName: PropTypes.string,
    timestamp: PropTypes.string,
    woNumber: PropTypes.string,
  }).isRequired,
  assetNumber: PropTypes.string,
  link: PropTypes.string.isRequired,
}
WorkOrderCreatedMessage.defaultProps = {
  assetNumber: null,
}

export default WorkOrderCreatedMessage
