import { useEffect, useRef, useState } from 'react'
import { Container, GridWrapper } from '@jsluna/react'
import { Card } from '@jsluna/card'
import { ProgressSpinner } from '@jsluna/progress'
import { Display3 } from '@jsluna/typography'
import AlarmCard from '../Cards/AlarmCard'
import { Pagination } from '../Pagination'
import useAlarms from '../../hooks/useAlarms'
import usePage from '../../hooks/usePage'
import { useCurrentStore } from '../../hooks'
import { NoFilteredAlarmsMessage } from '../Filtering/NoFilteredAlarmsMessage'
import { Heading4 } from '@jsluna/typography'

const AlarmsHistory = ({filters, handleFilters}: any) => {
  const storeCode = useCurrentStore().storeCode
  const pageType = "alarmHistoryPage"
  const {page, handlePage} = usePage(pageType)
  const [alarmsResource, alarmsLoader] = useAlarms()
  const [load, setLoad] = useState<boolean>(false)
  const filtersLength = useRef()
  let alarmsData = null
  let pagination = null

  useEffect(() => {
    const tempFiltersLength = filtersLength.current
    filtersLength.current = filters.length
    if (
      tempFiltersLength !== filtersLength.current &&
      page !== 0 &&
      tempFiltersLength !== undefined
    ) {
      handlePage(pageType, 0)
    } else {
      if (tempFiltersLength !== undefined) load ? setLoad(false) : setLoad(true)
    }
  }, [filters])

  useEffect(() => {
    alarmsLoader.current(storeCode, filters, page, "alarmHistory")
  }, [alarmsLoader, page, load])

  if(alarmsResource.hasLoaded && !alarmsResource.hasError){
    const responseData = alarmsResource.data
    pagination = ((responseData?.metadata?.pagination) ? responseData?.metadata : undefined)
    alarmsData = responseData?.results
  }

  const pageTitle = () => {
    return (
      <Container className="r-pageName ln-u-padding-ends*2" size="xs">
        <Display3 id="r-page-title" className="r-page-title">
          Store fixture alarms in last 4 weeks
        </Display3>
      </Container>
    )
  }

if(!alarmsResource.hasLoaded && !alarmsResource.hasError) return (
  <Container className="r-alarmsList" size="xs">
    {pageTitle()}
    <Card id="r-loadingElement" className="ln-u-text-align-center">
        <ProgressSpinner size="large" />
    </Card>
  </Container>
)


if(alarmsResource.hasError && filters?.length > 0) return (
  <>
    {pageTitle()}
    <Container className="r-alarmsList" size="xs">
      <NoFilteredAlarmsMessage filters={filters} handleFilters={handleFilters} />
    </Container>
  </>
)

if(alarmsResource.hasError && 
  (!alarmsData || alarmsData?.length <= 0) &&
  filters?.length === 0) return (
    <>
      {pageTitle()}
      <Container className="r-alarmsList" size="xs">
        <Heading4 className="ln-u-soft-top" id="r-no-alarms-message">
          No alarms were found for the last 4 weeks
        </Heading4>
      </Container>
    </>
  )

  if (alarmsData)
  return (
    <>
    {pageTitle()}
    <Container className="r-alarmsList" size="xs">
      <GridWrapper
      id="r-alarms-cards"
      matrix
      gutterSize="sm"
      className="ln-o-grid--gutter-zero@max-mobile"
      >
      {alarmsData.map((alarm, index) => (
          <AlarmCard alarm={alarm} key={index} modelType="alarmHistory" />
      ))}
      </GridWrapper>
    </Container>
    {pagination?.pagination && (
      <Pagination
        handlePagination={handlePage}
        currentPage={pagination.pagination.currentPage}
        lastPage={pagination.pagination.lastPage}
        pageType={pageType}
      />
    )}
    </>
  )
}

export default AlarmsHistory
