import { useCurrentStore } from "../hooks"

const FMAssistEnv = () => {
  switch (window.location.host) {
    case 'localhost:3000':
      return 'https://fmassist-dev.retail-azure.js-devops.co.uk'
    case 'refrigeration-dev.retail-azure.js-devops.co.uk':
      return 'https://fmassist-dev.retail-azure.js-devops.co.uk'
    case 'refrigeration-test.retail-azure.js-devops.co.uk':
      return 'https://fmassist-test.retail-azure.js-devops.co.uk'
    case 'refrigeration-preprod.retail-azure.js-devops.co.uk':
      return 'https://fmassist-preprod.retail-azure.js-devops.co.uk'
    case 'refrigeration.sainsburys.co.uk':
      return 'https://fmassist.sainsburys.co.uk'
    default:
      return 'default'
  }
}

const FmAssistAssetUrl = (assetNumber: string, link: string) => {
  const storeNumber = useCurrentStore().storeCode
  const storeName = useCurrentStore().storeName

  if (link === 'viewWorkOrder') {
    return `${FMAssistEnv()}/check_equipment/${assetNumber}?app=refrigeration&storeNumber=${storeNumber}&storeName=${storeName}`
  }
  if (link === 'raiseWorkOrder') {
    return `${FMAssistEnv()}/report_problem?app=refrigeration&tag=${assetNumber}&storeNumber=${storeNumber}&storeName=${storeName}`
  }
}

export default FmAssistAssetUrl
