import { useContext, useState } from 'react'
import { Card } from '@jsluna/card'
import { Container } from '@jsluna/react'
import { Body1, Display1, Caption } from '@jsluna/typography'
import { FormGroup, Label } from '@jsluna/form'
import { FilledButton } from '@jsluna/button'
import { foodSafetyGuide } from '../utils/linkConstants'
import BackButton from '../components/BackButton'
import FixtureHistory from '../components/FixtureDetailsPage/FixtureHistory'
import SiteCommunicationAlarmCard from '../components/Cards/AlarmCard/SiteCommunicationAlarmCard/SiteCommunicationAlarmCard'
import alarmsApi from '../api/alarmsApi'
import getRelativeDate from '../utils/relativeDate'
import { ProgressSpinner } from '@jsluna/progress'
import { AppContext, useApiClient } from '../context/AppContext'
import { useCurrentStore, useUser } from '../hooks'
import { useParams } from 'react-router'

const SiteCommunicationAlarmPage = () => {
  const storeCode = useCurrentStore().storeCode
  const { externalId } = useParams();
  const { username } = useUser()
  const appcontext = useContext(AppContext)

  let user = username
  let siteCommunicationAlarm: any
  let siteAlarmHistory: any

  if (appcontext && appcontext.appContextData.siteCommunicationAlarm) {
    siteCommunicationAlarm = appcontext.appContextData.siteCommunicationAlarm[`siteCommunicationAlarm${externalId}`]
  }

  if (appcontext && appcontext.appContextData.siteAlarmHistory) {
    siteAlarmHistory = appcontext.appContextData.siteAlarmHistory[`siteAlarmHistory${externalId}`]
  }

  const apiClient = useApiClient()
  const [errorMessage, setErrorMessage] = useState('')
  const [responseSent, setResponseSent] = useState(false)
  let buttonIsDisabled = false
  const [loading, setLoading] = useState(false)
  let alarmIsAnswered = false
  let date = new Date()

  const sendResponse = async () => {
    setLoading(true)
    buttonIsDisabled = true
    const postAlarmResponse = await alarmsApi.PostAlarmResponse(
      apiClient,
      storeCode,
      username,
      siteCommunicationAlarm,
      'confirm_action',
      'site communication alarm acknowledged',
    )
    if (postAlarmResponse?.error) {
      setErrorMessage(postAlarmResponse.data ?? 'Unable to submit the response')
      buttonIsDisabled = false
    } else {
      setResponseSent(true)
    }
    setLoading(false)
  }

  const checkAlarmEvents = () => {
    siteAlarmHistory?.eventsHistoryResponse.map((event: any) => {
      if (
        event?.alarmId === siteCommunicationAlarm?.id &&
        event?.answerType === 'confirm_action'
      ) {
        alarmIsAnswered = true
        user = event.user
        date = event.timestamp
        buttonIsDisabled = true
        return true
      }

      return false
    })
  }
  checkAlarmEvents()

  if (loading) {
    return (
      <Container
        className="ln-u-text-align-center ln-u-padding-top*4"
        size="xs"
      >
        <ProgressSpinner />
      </Container>
    )
  }

  return (
    <>
      <Container id="r-backButton" className="ln-u-padding-ends*3" size="xs">
        <BackButton />
      </Container>
      <Container
        id="r-siteCommunicationAlarmForm"
        className="ln-u-padding-ends*3"
        size="xs"
      >
        <SiteCommunicationAlarmCard
          alarm={siteCommunicationAlarm}
          siteAlarmHistory={siteAlarmHistory}
          design="detailsPage"
        />
        <div className="ln-u-padding*2">
          <Display1>
            Take manual temperature checks while we fix the problem
          </Display1>
        </div>
        <Card>
          <div className="ln-u-padding-bottom*3">
            All fixtures at your store <b>aren’t</b> being remotely monitored so
            you won’t receive any alarms.
          </div>
          <div>
            A work order has been created, so an engineer can investigate
            (remotely or in store). If the engineer needs a fixture
            de-merchandised they’ll be in touch.
          </div>
          <Label className="ln-u-soft-top ln-u-flush-bottom">
            You need to:
          </Label>
          <ul className="ln-u-flush-bottom">
            <li>
              Go to 2 fixtures per cabinet run, inc. counters.{' '}
              <b>Take manual temperature checks within 30 mins,</b> if the
              fixture is stocked. Record these temperatures on your Manual
              Temperature Check sheet
            </li>
            <li>
              Schedule AM (before store opens) and PM manual temperatures checks
              at top, middle and bottom of the fixture, using an air probe,
              until you receive an email saying alerts have been restored.
              Record these temperatures on your Manual Temperature Check sheet
            </li>
            <li className="ln-u-font-weight-bold">
              If communications have not been restored by 8am tomorrow. AM and
              PM manual temperature checks need to be taken for{' '}
              <Body1 className="ln-u-text-decoration-underline ln-u-font-weight-bold">
                every fixture
              </Body1>{' '}
              until communications been restored.
            </li>
          </ul>
          <Label className="ln-u-soft-top ln-u-flush-bottom">
            More information
          </Label>
          <div className="ln-u-soft-bottom">
            For more guidance and manual temperature sheets, please refer to:{' '}
            <Body1 className="ln-u-text-decoration-underline ln-u-font-weight-bold">
              <a href={foodSafetyGuide}>
                Food Safety: Guide for dealing with RTMS alarms and temperature
                checks
              </a>
            </Body1>
          </div>
          <FormGroup
            name="r-submit-button"
            error={errorMessage}
            className="ln-u-flush-bottom"
          >
            <FilledButton
              id="r-submitButton"
              fullWidth
              disabled={responseSent ? true : buttonIsDisabled}
              onClick={() => sendResponse()}
            >
              Start manual temperature checks
            </FilledButton>

            {alarmIsAnswered || responseSent ? (
              <div className="ln-u-margin-top">
                <Caption id="r-tag-reportingDate" className="ln-u-color-grey">
                  Acknowledged by {user} on {getRelativeDate(date)}
                </Caption>
              </div>
            ) : null}
          </FormGroup>
        </Card>
      </Container>
      {siteAlarmHistory === null ? null : (
        <FixtureHistory assetNumber={null} events={siteAlarmHistory.eventsHistoryResponse} />
      )}
    </>
  )
}

export default SiteCommunicationAlarmPage
