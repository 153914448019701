import { useContext, useEffect } from 'react'
import Error from '../components/Error'
import BackButton from '../components/BackButton'
import ChangeMerchandiseForm from '../components/FixtureDetailsPage/ChangeMerchandiseForm'
import FixtureAlarmCheckForm from '../components/FixtureDetailsPage/FixtureAlarmCheckForm/IndexForm'
import Tag from '../components/Cards/Tag'
import { Container } from '@jsluna/react'
import { Card } from '@jsluna/card'
import { Display3 } from '@jsluna/typography'
import { ProgressSpinner } from '@jsluna/progress'
import checkAlarmParameters from '../utils/checkAlarmParameters'
import { setCardColor } from '../utils/cardColor'
import FixtureNumber from '../components/Cards/Components/FixtureNumber'
import AssetNumber from '../components/Cards/Components/AssetNumber'
import ProductType from '../components/Cards/Components/ProductType'
import Department from '../components/Cards/Components/Department'
import ChildAssets from '../components/Cards/Components/ChildAssets'
import ResolvedAlarmMessage from '../components/FixtureDetailsPage/FixtureAlarmCheckForm/AlarmsResolvingMessages'
import FixtureHistory from '../components/FixtureDetailsPage/FixtureHistory'
import RaiseWorkOrderLink from '../components/FixtureDetailsPage/RaiseWorkOrderLink/RaiseWorkOrderLink'
import useAssetsDetails from '../hooks/useAssetsDetails'
import { useParams } from 'react-router'
import { IAssetsDetails } from '../types'
import { AppContext } from '../context/AppContext'
import { useCurrentStore } from '../hooks'

const FixtureDetailsPage = () => {
  const { assetNumber } = useParams();
  const [assetsDetailsResource, assetsDetailsLoader] = useAssetsDetails()
  const appcontext = useContext(AppContext)
  const storeCode = useCurrentStore().storeCode

  let assetsDetailsData : IAssetsDetails | null | undefined = null
  let card : { cardColor: string, tagColor: string } | null = null

  useEffect(() => {
    assetsDetailsLoader.current(assetNumber, storeCode)
  }, [assetsDetailsLoader, assetNumber])

  const storedState = sessionStorage.getItem('AppContextData');
  if (appcontext && appcontext.appContextData.assets && appcontext.appContextData.assets[assetNumber] && storedState) {
    assetsDetailsData = appcontext?.appContextData.assets[assetNumber]
  }

  if((appcontext?.appContextData.assets === undefined || !appcontext?.appContextData.assets[assetNumber]) &&
    assetsDetailsResource.hasLoaded && !assetsDetailsResource.hasError)
  {
    assetsDetailsData = assetsDetailsResource.data
  }

  const displayAlarmCheck = (alarm: {status:string, lastResponse: string, woNumber: string}) =>
    (assetsDetailsData && assetsDetailsData.isPackParent && alarm.status !== 'Closed') ||
    (alarm.lastResponse === null &&
      (alarm.woNumber === null || alarm.woNumber === '')) ||
    (alarm.lastResponse !== null &&
      (alarm.woNumber !== null || alarm.woNumber !== '')) ||
    (alarm.lastResponse !== null &&
      (alarm.woNumber === null || alarm.woNumber === '')) ||
    (alarm.woNumber !== null && alarm.woNumber !== '')

  const showResolvedAlarmEvents = () => {
    return checkAlarmParameters(assetsDetailsData && assetsDetailsData.assetEventsHistoryResponse)
  }

  const isAlarmed = () => {
    return assetsDetailsData && assetsDetailsData.alarms?.length > 0
  }

  const pageTitle = () => {
    return (
      <Container className="ln-u-padding-ends*3" size="xs">
        <BackButton />
        <div>
          <Display3 id="r-page-title" className="r-page-title">
            Fixture details
          </Display3>
        </div>
      </Container>
    )
  }

  if(!assetsDetailsResource.hasLoaded && !assetsDetailsResource.hasError) return (
    <>
    {pageTitle()}
    <Container className="ln-u-text-align-center ln-u-padding-top*4" size="xs">
      <ProgressSpinner />
    </Container>
    </>
  )

  if(assetsDetailsResource.hasError && !assetsDetailsData) return (
    <Error
      message="An error has occurred."
      tryAgain={() => window.location.reload()}
    />
  )

  if (assetsDetailsData && !assetsDetailsData.monitoringStatus && assetsDetailsData.alarms?.length > 0) {
    card = setCardColor(assetsDetailsData.alarms[0])
  }


  if (assetsDetailsData && assetsDetailsData !== undefined)
  return (
    <>
      {pageTitle()}
      <Container size="xs">
        {
          <Card
          className={`r-asset-card ${
             !assetsDetailsData.monitoringStatus && assetsDetailsData.alarms?.length > 0
              ? card?.cardColor
              : 'r-assetCard-noTag'
          }`}
        >
          <FixtureNumber
            details={assetsDetailsData.merchandising}
            siteName={assetsDetailsData.siteName}
          />
          <AssetNumber assetNumber={assetsDetailsData.assetNumber} />
          <ProductType details={assetsDetailsData} cardType="assetDetailsPage" />
          <Department department={assetsDetailsData.department} />
          <Tag
            id="r-asset-tag"
            className=" ln-u-soft-bottoms"
            asset={assetsDetailsData}
            tagColor={card && card.tagColor}
          />
          <ChildAssets data={assetsDetailsData} />
        </Card>
        }
      </Container>

      {<ResolvedAlarmMessage alarmClosureDetails={showResolvedAlarmEvents()} /> }

      {assetsDetailsData && assetsDetailsData.alarms?.length > 0 ? (
        <div id="r-alarm-check-form">
          {assetsDetailsData.alarms.map(alarm =>
            displayAlarmCheck(alarm) ? (
              <FixtureAlarmCheckForm key={alarm.assetNumber}
                alarm={alarm}
                historyEvents={assetsDetailsData && assetsDetailsData.assetEventsHistoryResponse}
              />
            ) : null,
          )}
        </div>
      ) : null}
      {!isAlarmed() ? <RaiseWorkOrderLink assetNumber={assetNumber} /> : null}


      {assetsDetailsData.merchandising?.productType != null && !assetsDetailsData.isPackParent ? (
        <ChangeMerchandiseForm
          isAlarmed={isAlarmed()}
          merchandisingDetails={{
            siteName: assetsDetailsData.siteName,
            assetNumber,
            fixtureNumber: assetsDetailsData.merchandising?.fixtureNumber,
            productType: assetsDetailsData.merchandising?.productType,
          }}
          productTypeChangeDetails={assetsDetailsData.changeRequest}
        />
      ) : null}

      {<FixtureHistory
        assetNumber={assetNumber}
        events={assetsDetailsData.assetEventsHistoryResponse}
      />
      }
    </>
  )
}

export default FixtureDetailsPage
